export function handleClass(el, classes, reason='add') {
 classes=Array.isArray(classes) ? classes : [classes];
 classes.forEach((className) => (el.classList[reason](className)));
}

export function addClass(element, classes) {
 handleClass(element, classes, 'add');
}

export function removeClass(element, classes) {
 handleClass(element, classes, 'remove');
}

// create element
export function createElement(tag, options={}) {
 const element=document.createElement(tag);

 if (options.classes) {
 addClass(element, options.classes);
 }

 if (options.innerHTML) {
 element.innerHTML = options.innerHTML;
 }

 Object.keys(options.attrs || {})
 .forEach((key) => (element.setAttribute(key, options.attrs[key])));

 Object.keys(options.listeners || {})
 .forEach((key) => (element[key] = options.listeners[key]));

 (!options.appendChild ? [] : Array.isArray(options.appendChild) ? options.appendChild : [options.appendChild])
 .forEach((el) => (element.appendChild(el)));

 return element;
}

// slugfy method
export function slugify(value) {
 if (value) {
 return value
 .toLowerCase()
 .replace(/[áãâà]/g, "a")
 .replace(/[éẽêè]/g, "e")
 .replace(/[íĩîì]/g, "i")
 .replace(/[óõôò]/g, "o")
 .replace(/[úũûù]/g, "u")
 .replace(/[ç]/g, "c")
 .replace(/ /g, "-")
 .replace(/["'!?()]/g, "");
 }

 return value;
}

// add metrics
export function handleAudience(action, position, reference) {
 window.hitAudience.countClick({
 'position': position,
 'action': action,
 'reference': slugify(reference),
 'central': 'copas',
 'channel': 'copa 2022',
 'mediaName': 'no-media',
 'area': 'footer-flutuante',
 'component': 'ticker-copa22',
 });
}