/* eslint-disable */

import css from 'bundle-text:./style.scss';
import { createElement, handleAudience } from './utils';
import worldcup2022Svg from 'bundle-text:./logo-worldcup.svg';
import braSvg from 'bundle-text:./bra.svg';
import starSvg from 'bundle-text:./star.svg';
import separatorSvg from 'bundle-text:./separator.svg';
import logoUolSvg from 'bundle-text:./logo-uol.svg';

let SOCKET_CHANNEL='rtevent.uol.com';
const gamesMap={};
let worlcupMacthesId=[];
let isMobile=false;
let linkParam='';
let scope='';
let editorialConfig='';

// variable to info team advertising
window.showAnchorads = true;

// Load page
(function () {
 if (document.readyState == "complete") {
 getInitConfig();
 } else {

 window.addEventListener("load", function () {
 getInitConfig();
 });
 }
})();

const getInitConfig=async () => {
 console.info('getting data config ticker');

 try {
 editorialConfig=await getDefinitions();
 await configWatcher();
 } catch (e) {
 console.error(e);
 return;
 }

 init(editorialConfig);
}

// check if url is partner or UOL
scope = checkUrlPartner() ? 'Partners' : 'UOL';

/*
 * get configurations worldcup 2022
 */
async function getDefinitions() {
 // request to config component
 const commonsPath="//c.jsuol.com.br/service?loadComponent=projects/worldcup-2022/worldcup-2022-ticker-config&data={%22jsonp%22:false}&dataType=config";

 return await fetch(commonsPath, { cache: "no-cache" })
 .then(data => {
 return data.json()
 })
 .then((response) => {
 return response;
 })
 .catch(err => console.info('Error loading Worldcup Ticker', err));
}

/*
 * get worldcup games on commons
 */
async function getWorldcupGames() {
 // request to config component
 const commonsPath="//c.jsuol.com.br/service?loadComponent=projects/worldcup-2022/worldcup-2022-ticker-config&data={%22jsonp%22:false}&dataType=games";

 return await fetch(commonsPath, { cache: "no-cache" })
 .then(data => {
 return data.json()
 })
 .then(({ games }) => games)
 .catch(err => console.info('Error to get games', err));
}

/*
 * get main worldcup game on commmons
 */
async function getMainGame() {
 // request to config component
 const commonsPath="//c.jsuol.com.br/service?loadComponent=projects/worldcup-2022/worldcup-2022-ticker-config&data={%22jsonp%22:false}&dataType=mainGame";

 return await fetch(commonsPath, { cache: "no-cache" })
 .then(data => {
 return data.json()
 })
 .then(({ game }) => {
 if (game.competition === 'Copa do Mundo') {
 return game;
 } else {
 return;
 }
 })
 .catch(err => console.info('Error to get main game', err));
}

/*
 * get Games data on commons
 */
async function getGamesDataOnCommons() {
 // request to component data 
 const commonsPath="//c.jsuol.com.br/service?loadComponent=projects/worldcup-2022/worldcup-2022-ticker-data&data={%22jsonp%22:false}";

 return await fetch(commonsPath, { cache: "no-cache" })
 .then(data => {
 return data.json()
 })
 .then(({ gamesDataCommons }) => gamesDataCommons)
 .catch(err => console.info('Error to get games on commons', err));
}

/*
 * watcher to check editorial config
 */
async function configWatcher() {
 setInterval(async () => {
 //transform obj in string to compare configs
 const newConfig=JSON.stringify(await getDefinitions());
 const currentConfig=JSON.stringify(editorialConfig);

 if (newConfig === currentConfig) {
 return;
 } else {
 editorialConfig=JSON.parse(newConfig);
 const ticker=document.querySelector('#worldcup-2022-ticker');
 if (ticker) ticker.remove();
 window.usocket.disconnect();
 init(editorialConfig);
 }
 }, 60000);
}

/*
 * waiting for conection socket
 */
function waitWsSocket() {
 setTimeout(function () {
 if (window.usocket !== undefined) {

 return new Promise((resolve) => {
 // start ws first time
 connectSocket(SOCKET_CHANNEL, 'placar-futebol-live')
 .then((data) => {
 resolve(data);
 });
 });
 } else {
 waitWsSocket();
 }
 }, 500);
};

/** 
 * conection on socket
 **/
function connectSocket(domain, channel) {
 console.info('Worldcup Ticker - Getting DATA from Socket')
 return new Promise((resolve) => {

 if (!window.usocket.isConnected()) window.usocket.config({ domain });

 let allSubchannels={};

 // add polling to get first data cards on socket
 if (window.usocket) {
 window.usocket.polling(channel, (err, data) => {
 allSubchannels.subchannels = data.subchannels;
 checkDataToUpdateCards(allSubchannels);
 })
 }

 const ws=window.usocket.connect(channel);

 const connectionRetry=function () {
 if (window.channelTryConnection) {
 window.channelTryConnection -= 1;

 window.setTimeout(() => {
 console.info('Trying to connect socket, channel: ', channel);
 connectSocket(domain, channel);
 }, 60000);
 }
 }

 ws.on('message', data=> {
 console.info('Worldcup Ticker Socket Data', data);

 if (!data) {
 console.info('Missing essential data, retrying conection with socket: ', channel);
 connectionRetry();
 resolve();
 return;
 }

 if (document.querySelector('#worldcup-2022-ticker')) {
 checkDataToUpdateCards(data);
 resolve();
 } else {
 resolve(data);
 }

 if (data.invalidChannel) {
 connectionRetry();
 }
 });

 ws.on('error', () => {
 console.info('connectSocket ERROR ~ domain, channel ', domain, channel);
 connectionRetry();
 })

 ws.on('open', data=> {
 console.info('SOCKET OPEN', data);
 window.channelTryConnection = 3
 });

 ws.on('close', data=> {
 console.info('SOCKET CLOSE', data);
 });
 });
}

/** 
 * check if worldcup ticker is render at partner
 **/
function checkUrlPartner() {
 const partners=[
 'anamaria.uol.com.br',
 'aventurasnahistoria.uol.com.br',
 'bnews.com.br',
 'caras.uol.com.br',
 'cinebuzz.uol.com.br',
 'contigo.uol.com.br',
 'costanorte.com.br',
 'maxima.uol.com.br',
 'natelinha.uol.com.br',
 'ofuxico.com.br',
 'olhardigital.com.br',
 'paranaportal.uol.com.br',
 'recreio.uol.com.br',
 'www.redetv.uol.com.br',
 'rollingstone.uol.com.br',
 'sportbuzz.uol.com.br',
 'tnonline.uol.com.br',
 'cultura.uol.com.br',
 'observatoriodatv.uol.com.br',
 'observatoriodocinema.uol.com.br',
 'observatoriodosfamosos.uol.com.br',
 'observatoriog.bol.uol.com.br',
 'observatoriodegames.uol.com.br',
 'terceirotempo.uol.com.br',
 'biologianet.com',
 'futhistoria.com.br',
 'historiadomundo.com.br',
 'manualdaquimica.com',
 'mundoeducacao.uol.com.br',
 'ne10.uol.com.br',
 'interior.ne10.uol.com.br',
 'jc.ne10.uol.com.br',
 'radiojornal.ne10.uol.com.br',
 'tvjornal.ne10.uol.com.br',
 'revistaanamaria.com.br'
 ]

 const partnerURL=partners.find(url => window.location.href.indexOf(url) != -1);

 if (partnerURL) {
 SOCKET_CHANNEL="rtpartner.uol.com";
 linkParam=`?utm_source=${partnerURL}&utm_medium=ticker&utm_campaign=copa22&utm_content=`;
 return true
 }

 return false
};

/** 
 * initializing wordcup ticker
 **/
const init=async (config) => {

 console.info('Worldcup ticker init');

 // Check scope to get config of Partner or UOL to disabled ticker
 if (!config[`status${scope}`]) {
 console.info('Worldcup Ticker Disabled');
 return;
 }

 // info to advertising
 window.showAnchorads = false;

 // Check LGPD or anchorAds active
 if (scope === 'UOL' && (document.querySelector(".banner-lgpd-consent-container") || document.body.dataset.anchorads == true)) {
 console.info('Worlcup ticker disabled - anchorads active');
 return;
 }

 function urlUOLStations(selectedStation) {
 const stations={
 'canaluol': 'uol.com.br/play',
 'carros': 'uol.com.br/carros',
 'ecoa': 'uol.com.br/ecoa',
 'economia': 'economia.uol.com.br',
 'educacao': 'educacao.uol.com.br',
 'esporte': 'uol.com.br/esporte',
 'noticias': 'noticias.uol.com.br',
 'nossa': 'uol.com.br/nossa',
 'splash': 'uol.com.br/splash',
 'tab': 'tab.uol.com.br',
 'tilt': 'uol.com.br/tilt',
 'universa': 'uol.com.br/universa',
 'vivabem': 'uol.com.br/vivabem'
 }
 return stations[selectedStation] || ''
 }

 // Check if show ticker in all stations
 if (scope === 'UOL' && config.options == 'algumas') {
 let urlStations=[];
 let stationsRemoved=[];
 let urls=[];

 const allUrlStations=[
 'canaluol',
 'carros',
 'ecoa',
 'economia',
 'educacao',
 'esporte',
 'noticias',
 'nossa',
 'splash',
 'tab',
 'tilt',
 'universa',
 'vivabem'
 ]

 urlStations=config.stations.split(';');

 stationsRemoved=allUrlStations.filter(item => !urlStations.includes(item));
 stationsRemoved.forEach(item => {
 urls.push(urlUOLStations(item));
 });

 if (urls.find(url => window.location.href.indexOf(url) != -1)) {
 console.info('Worldcup Ticker Disabled');
 return
 }
 }

 // Check if url is advertising page to not rendering worldcup ticker
 if (window.location.href.includes('conteudo-de-marca') ||
 window.location.href.includes('conteudo-publicitario') ||
 window.location.href.includes('ao-vivo')) {
 console.info('Worlcup ticker disabled');
 return;
 }

 // Check if url is "create your team" page to not rendering worldcup ticker
 if (window.location.href.includes('escale-seu-time') || window.location.href.includes('copa-do-mundo/escale')) {
 console.info('Worlcup ticker disabled');
 return;
 }

 // check if is mobile 
 isMobile = window.innerWidth < 1230;

 // add inline css
 document.head.appendChild(
 createElement('style', {
 appendChild: document.createTextNode(css),
 })
 );

 // usocket script
 document.head.appendChild(
 createElement('script', {
 attrs: {
 src: '//c.jsuol.com.br/assets/?loadComponent=media&contentType=js&tpl=assets/dist/libs/usocket',
 async: '',
 }
 })
 );

 // audience script
 document.head.appendChild(
 createElement('script', {
 attrs: {
 src: '//c.jsuol.com.br/assets/?loadComponent=media&contentType=js&tpl=assets/dist/audience/audience.min',
 async: '',
 }
 }),
 );

 // ticker skeleton
 const container = createElement('div', {
 classes: ['ticker-container', 'is-open']
 });

 const gamesWrapper = createElement('div', {
 classes: 'games-wrapper'
 });

 const worldcupTicker = createElement('div', {
 attrs: { id: 'worldcup-2022-ticker' },
 appendChild: container,
 });

 // add class to partner
 if (scope !== 'UOL') {
 worldcupTicker.classList.add('partner');
 }

 // rule for this partner
 if (scope === 'Partners' && window.location.href.includes('paranaportal.uol.com.br')) {
 if (worldcupTicker) {
 worldcupTicker.classList.add('parana-portal');
 }
 }

 // worldcup logo metrics
 const btnToggleMetrics = {
 position: 1,
 action: '',
 reference: 'content'
 }

 // button toggle
 const btnToggle = createElement('button', {
 classes: 'btn-toggle',
 appendChild: document.createTextNode('esconder placar'),
 listeners: {
 onclick: function () {
 if (container) {
 container.classList.toggle('is-open');
 // set info on local storage
 if (container.classList.contains('is-open')) {
 btnToggle.textContent = 'esconder placar';
 localStorage.setItem('worldcupTicker', 'opened');
 } else {
 btnToggle.textContent = 'mostrar placar';
 localStorage.setItem('worldcupTicker', 'closed');
 }
 }
 handleAudience(
 container.classList.contains('is-open')
 ? 'interaction-open'
 : 'interaction-close', btnToggleMetrics.position, btnToggleMetrics.reference
 );
 },
 },
 });

 // always show games on desktop
 if (!isMobile) {
 container.classList.add('is-open');
 }

 // check if hide ticker was selected
 if (scope === 'UOL' && isMobile) {
 if (localStorage.getItem('worldcupTicker') === 'closed') {
 container.classList.remove('is-open');
 btnToggle.textContent = 'mostrar placar';
 }
 }

 // header elements
 const starHeader = createElement('li', {
 classes: 'worldcup-star-header',
 innerHTML: starSvg,
 });

 // worldcup logo metrics
 const metricsLogoWorldcup = {
 position: 1,
 action: 'https://www.uol.com.br/esporte/futebol/copa-do-mundo',
 reference: 'logo-copa-2022'
 }

 const worldcupLink = createElement('a', {
 classes: 'title-link',
 innerHTML: worldcup2022Svg,
 attrs: {
 href: 'https://www.uol.com.br/esporte/futebol/copa-do-mundo',
 target: '_blank'
 },
 listeners: {
 onclick: function () {
 handleAudience(
 linkParam
 ?
 `${metricsLogoWorldcup.action}${linkParam}tabela-da-copa`
 : metricsLogoWorldcup.action,
 metricsLogoWorldcup.position,
 metricsLogoWorldcup.reference
 );
 }
 }
 });

 // metrics logo UOL
 const metricsLogoUOL = {
 position: 1,
 action: 'https://www.uol.com.br',
 reference: 'logo-uol'
 }

 const logoUOL = createElement('a', {
 classes: 'logo-uol',
 innerHTML: logoUolSvg,
 attrs: {
 href: 'https://www.uol.com.br',
 target: '_blank'
 },
 listeners: {
 onclick: function () {
 handleAudience(
 linkParam
 ?
 `${metricsLogoUOL.action}${linkParam}UOL`
 : metricsLogoUOL.action,
 metricsLogoUOL.position,
 metricsLogoUOL.reference
 );
 }
 }
 });

 const title = createElement('div', {
 classes: 'ticker-title',
 appendChild: worldcupLink,
 });

 const tickerHeaderItems = createElement('li', {
 classes: 'ticker-header-item',
 appendChild: title,
 });

 const tickerHeaderList = createElement('ul', {
 classes: 'ticker-header-list',
 appendChild: [starHeader, tickerHeaderItems],
 });

 if (scope === 'Partners') {
 tickerHeaderItems.appendChild(logoUOL);
 };

 const tickerHeader = createElement('div', {
 classes: 'ticker-header',
 appendChild: tickerHeaderList,
 });

 // footer elements
 const starFooter = createElement('div', {
 classes: 'worldcup-star-footer',
 innerHTML: starSvg,
 });

 const tickerFooterList = createElement('ul', {
 classes: 'ticker-footer-list',
 });

 const separator = createElement('div', {
 classes: 'games-separator',
 innerHTML: separatorSvg,
 });

 const tickerFooter = createElement('div', {
 classes: 'ticker-footer',
 appendChild: [separator, tickerFooterList, starFooter],
 });

 for (let item = 0; item <= 1; item++) {
 const tickerFooterItems=createElement('li', {
 attrs: {
 id: `ticker-footer-item-${item}`,
 },
 });

 tickerFooterList.appendChild(tickerFooterItems);
 }

 // world cup table metrics
 const worldcupTableMetrics={
 position: 1,
 action: 'https://www.uol.com.br/esporte/futebol/copa-do-mundo/tabela-da-copa/',
 reference: 'tabela-copa'
 }

 const worldcupTable=createElement('a', {
 classes: 'worldcup-table',
 appendChild: document.createTextNode('tabela da copa'),
 attrs: {
 href: 'https://www.uol.com.br/esporte/futebol/copa-do-mundo/tabela-da-copa/',
 title: 'Tabela da copa',
 target: '_blank'
 },
 listeners: {
 onclick: function () {
 handleAudience(
 linkParam
 ?
 `${worldcupTableMetrics.action}${linkParam}tabela-da-copa`
 : worldcupTableMetrics.action,
 worldcupTableMetrics.position,
 worldcupTableMetrics.reference
 );
 }
 }
 });

 // world cup table metrics
 const brazilUrlMetrics={
 position: 1,
 action: 'https://www.uol.com.br/esporte/futebol/selecoes/selecao-brasileira/',
 reference: 'selecao'
 }

 const brazilUrl=createElement('a', {
 classes: 'brazil-url',
 appendChild: document.createTextNode('seleção'),
 attrs: {
 href: 'https://www.uol.com.br/esporte/futebol/selecoes/selecao-brasileira/',
 title: 'Seleção',
 target: '_blank'
 },
 innerHTML: braSvg,
 listeners: {
 onclick: function () {
 handleAudience(
 linkParam
 ?
 `${brazilUrlMetrics.action}${linkParam}selecao`
 : brazilUrlMetrics.action,
 brazilUrlMetrics.position,
 brazilUrlMetrics.reference
 );
 }
 }
 });

 const tableLinkLi=tickerFooterList.querySelector('#ticker-footer-item-0');
 const brazilLinkLi=tickerFooterList.querySelector('#ticker-footer-item-1');

 tableLinkLi.appendChild(worldcupTable);
 brazilLinkLi.appendChild(brazilUrl);

 const games=createElement('ul');

 /** 
 * initializing worlcup cards in ticker
 **/
 const createWorldcupCardGames=(gamesData, mainGameData, gamesDataCommons) => {
 let sortedDataGames=[];
 let isMainGameFinished=false;

 // check if main game 1 was finished
 if (mainGameData && Object.values(gamesDataCommons).length > 0) {
 if (gamesDataCommons[mainGameData.idMatch]) {
 isMainGameFinished=gamesDataCommons[mainGameData.idMatch].placar1 !== null;
 } else {
 mainGameData=null;
 }
 }

 // Check if only gamesData true
 if (gamesData && !mainGameData) {
 sortedDataGames=gamesData;
 }

 // Check if only mainGameData true
 if (mainGameData && !isMainGameFinished && !gamesData) {
 sortedDataGames=mainGameData;
 }

 //check if main game is include at list of games
 if (mainGameData && !isMainGameFinished) {
 if (!gamesData.some(e => e.idMatch === mainGameData.idMatch)) {
 gamesData.unshift(mainGameData);
 sortedDataGames=gamesData;
 } else {
 sortedDataGames=gamesData.filter(item => item.idMatch !== mainGameData.idMatch)
 sortedDataGames.unshift(mainGameData);
 }
 } else {
 sortedDataGames=gamesData;
 }

 // ticker show only 3 games
 const gamesCardsData=sortedDataGames.length >= 3 ? sortedDataGames.slice(0, 3) : sortedDataGames;

 // get ids games
 worlcupMacthesId=gamesCardsData.map(item => item.idMatch);

 const existentCards=games.querySelectorAll('li');

 // check if is final game
 if (container) {
 if (gamesCardsData.length == 1) {
 container.classList.add('final-game');
 }
 }

 ;[].forEach.call(existentCards, function (item) {
 const id=item.getAttribute('id').split('-').pop();
 if (!gamesCardsData.find(({ idMatch }) => idMatch== id)) {
 item.remove();
 }
 })

 for (const game of Object.values(gamesCardsData)) {
 const gameCard=games.querySelector(`[id=game-${game.idMatch}]`);
 if (!gameCard) {
 // add separator in games
 const gameSeparator=createElement('div', {
 classes: 'games-separator',
 innerHTML: separatorSvg,
 });

 games.appendChild(
 createElement('li', {
 appendChild: [createCard(game), gameSeparator],
 attrs: {
 id: `game-${game.idMatch}`
 }
 })
 );
 }
 }
 }

 createWorldcupCardGames(await getWorldcupGames(), await getMainGame(), await getGamesDataOnCommons());
 setInterval(async () => {
 createWorldcupCardGames(await getWorldcupGames(), await getMainGame(), await getGamesDataOnCommons());
 }, 60000);

 gamesWrapper.appendChild(games);
 container.appendChild(tickerHeader);
 container.appendChild(tickerFooter);
 container.appendChild(gamesWrapper);
 container.appendChild(btnToggle);

 document.body.appendChild(worldcupTicker);

 waitWsSocket();
};

/*
 * Check if game id contains in list to update card
 */
const checkDataToUpdateCards=(gamesData) => {
 for (game in gamesData.subchannels) {

 const match=gamesData.subchannels[game];

 // update card if contains in id list
 if (worlcupMacthesId.includes(match.id)) {
 const socketGamesMap={
 team1: {
 name: match.teams.home['short-name'],
 flag: match.teams.home.image,
 score: match.teams.home.goals,
 penalties: match.teams.home.penalties
 },
 team2: {
 name: match.teams.away['short-name'],
 flag: match.teams.away.image,
 score: match.teams.away.goals,
 penalties: match.teams.away.penalties
 },
 status: match.status,
 urlScore: match.url,
 dateTime: match.date
 }

 const newDataMatch=socketGamesMap;
 updateCard(match.id, newDataMatch);
 }
 }
}

/*
 * update card game 
 */
const updateCard=(id, data) => {
 if (!gamesMap[id]) {
 return;
 }

 ['team1', 'team2']
 .forEach((item) => {
 gamesMap[id][item].score.innerHTML = data[item].score;

 // add penalty score only if data exist
 if (data[item].penalties >= 0) {
 gamesMap[id][item].penalties.innerHTML = data[item].penalties;
 gamesMap[id][item].penalties.parentElement.classList.add('penalty');
 }
 });

 const statusMap={
 2: 'ao vivo',
 3: 'encerrado',
 4: 'pré-jogo',
 5: 'encerrado'
 }

 gamesMap[id].urlScore.href = data.urlScore;
 gamesMap[id].urlScore.target = '_blank';
 gamesMap[id].matchInfo.classList[data.status === 4 ? 'add' : 'remove']('pre-game');
 gamesMap[id].matchInfo.classList[data.status === 2 ? 'add' : 'remove']('live');
 gamesMap[id].matchInfo.classList[data.status === 3 || data.status === 5 ? 'add' : 'remove']('finished');

 if (statusMap[data.status]) {
 gamesMap[id].status.innerHTML = statusMap[data.status];
 }

 if (data.status === 4) {
 gamesMap[id].time.innerHTML = `- ${data.dateTime.toString().slice(8, 10)}h`;
 }
}

/*
 * create card game
 */
const createCard=(dataCard) => {
 const teamHomeAbbr = createElement('abbr', {
 classes: 'team-home-abbr',
 appendChild: document.createTextNode(dataCard.teams[0].slug || ''),
 });

 const teamAwayAbbr = createElement('abbr', {
 classes: 'team-away-abbr',
 appendChild: document.createTextNode(dataCard.teams[1].slug || ''),
 });

 const teamVs = createElement('div', {
 classes: 'team-vs',
 appendChild: document.createTextNode('x'),
 });

 const teamHomeScore = createElement('div', {
 classes: 'team-home-score',
 });

 const teamAwayScore = createElement('div', {
 classes: 'team-away-score',
 });

 const teamAwayPenaltyScore = createElement('div', {
 classes: 'team-away-penalty-score',
 });

 const teamHomePenaltyScore = createElement('div', {
 classes: 'team-home-penalty-score',
 });

 const teamHomeFlag = createElement('img', {
 classes: 'team-home-flag',
 attrs: {
 src: dataCard.teams[0].photo.images[0].src || 'https://imguol.com/c/geral/3d/2021/05/25/placeholder-image-1621949831997_v2_300x300.jpg',
 width: '20',
 height: '20',
 }
 });

 const teamAwayFlag = createElement('img', {
 classes: 'team-away-flag',
 attrs: {
 src: dataCard.teams[1].photo.images[0].src || 'https://imguol.com/c/geral/3d/2021/05/25/placeholder-image-1621949831997_v2_300x300.jpg',
 width: '20',
 height: '20',
 }
 });

 const imgHomeContent = createElement('div', {
 appendChild: teamHomeFlag,
 });

 const imgAwayContent = createElement('div', {
 appendChild: teamAwayFlag,
 });

 const teamHome = createElement('div', {
 classes: ['team', 'team-home'],
 appendChild: [teamHomeAbbr, imgHomeContent, teamHomeScore, teamHomePenaltyScore],
 });

 const teamAway = createElement('div', {
 classes: ['team', 'team-away'],
 appendChild: [teamAwayAbbr, imgAwayContent, teamAwayScore, teamAwayPenaltyScore],
 });

 const matchScore = createElement('div', {
 classes: 'match-score',
 appendChild: [teamHome, teamVs, teamAway],
 });

 const gameStatus = createElement('div', {
 classes: 'game-status',
 innerHTML: dataCard.date ? `${dataCard.date} - ` : '',
 });

 const gameTime = createElement('div', {
 classes: 'game-time',
 innerHTML: dataCard.time ? dataCard.time.slice(0, 3) : '',
 })

 const matchInfo = createElement('div', {
 classes: 'match-info',
 appendChild: [gameStatus, gameTime],
 });

 const matchContent = createElement('div', {
 classes: 'match-content',
 appendChild: [matchScore, matchInfo],
 });

 const linkScorePage = createElement('a', {
 appendChild: matchContent,
 listeners: {
 onclick: function () {
 handleAudience(
 linkParam
 ?
 `${linkScorePage.href}${linkParam}jogos`
 : linkScorePage.href,
 linkScoreMetrics.position,
 linkScoreMetrics.reference
 );
 }
 }
 });

 // metrics anchor card
 const linkScoreMetrics = {
 position: 1,
 reference: 'jogo'
 }

 if (!dataCard.date) {
 matchInfo.classList.add('only-time');
 }

 if (dataCard.link) {
 linkScorePage.href = dataCard.link;
 linkScorePage.target = '_blank';
 }

 gamesMap[dataCard.idMatch] = {
 team1: {
 name: teamHomeAbbr,
 flag: teamHomeFlag,
 score: teamHomeScore,
 penalties: teamHomePenaltyScore
 },
 team2: {
 name: teamAwayAbbr,
 flag: teamAwayFlag,
 score: teamAwayScore,
 penalties: teamAwayPenaltyScore
 },
 status: gameStatus,
 urlScore: linkScorePage,
 matchInfo: matchInfo,
 time: gameTime
 }

 return createElement('div', {
 classes: 'match',
 appendChild: linkScorePage,
 });
};
